<template>
    <div class="quotation-index">
        <MainHeader />
        <CommonTop />
        <div class="page-content-wrap">
            <div class="section info-box">
                <div class="page-title">公司信息</div>
                <div class="info-content">
                    <div class="content-item">
                        <div class="label">制单人</div>
                        <div class="value">{{userInfo.name}}</div>
                    </div>
                    <div class="content-item">
                        <div class="label">公司名称</div>
                        <div class="value">{{userInfo.company_name}}</div>
                    </div>
                </div>
            </div>
            <div class="section list-box">
                <div class="page-title">商品明细</div>
                <div class="table-container">
                    <el-table
                        max-height="485"
                        ref="multipleTable"
                        :data="tableData"
                        tooltip-effect="dark"
                        style="width: 100%"
                        size="medium"
                        border
                        @selection-change="handleSelectionChange">
                        <el-table-column
                        type="selection"
                        align="center"
                        width="55">
                        </el-table-column>
                        <el-table-column
                        type="index"
                        label="编号"
                        :index="indexMethod"
                        align="center"
                        width="120">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        align="center"
                        label="规格型号">
                        <template slot-scope="scope">
                            <input type="text" placeholder="请输入产品规格型号" v-model="scope.row.production_model">
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="remark"
                        align="center"
                        label="备注">
                        <template slot-scope="scope">
                            <input type="text" placeholder="请输入备注" v-model="scope.row.remark">
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="address"
                        align="center"
                        label="数量"
                        width="120">
                        <template slot-scope="scope">
                            <input type="text" placeholder="请输入数量" v-model.number="scope.row.number">
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="actions">
                    <el-button @click="addHandle" type="warning">添加行</el-button>
                    <div class="right">
                        <i @click="deleteHandle" style="font-size: 28px; color: #cccccc; cursor: pointer; margin-right: 20px;" class="el-icon-delete"></i>
                        <el-button @click="downloadHandle" plain style="margin-right: 20px;">模板下载</el-button>
                        <el-upload
                            accept=".xls, .xlsx"
                            :headers="headers"
                            class="upload-demo"
                            :show-file-list="false"
                            name="excel"
                            :on-success="successHandle"
                            :action="action">
                            <el-button plain>Excell 批量导入</el-button>
                        </el-upload>
                    </div>
                </div>
                <el-button @click="clickHandle" class="next" type="primary">下一步</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
export default {
    components: {
        MainHeader,
        CommonTop
    },
    data () {
        return {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            action: process.env.VUE_APP_BASEURL + '/api/quotation/import-excel',
            tableData: [{
                production_model: '',
                remark: '',
                number: ''
                }, {
                production_model: '',
                remark: '',
                number: ''
                }, {
                production_model: '',
                remark: '',
                number: ''
                }],
            multipleSelection: []
        }
    },
    computed: {
        userInfo () {
            return this.$store.state.userInfo
        }
    },
    created () {
        console.log(this.$store)
    },
    methods: {
        async clickHandle () {
            if (this.tableData.length < 1) {
                this.$message({
                    message: '您当前尚未添加任何商品',
                    type: 'error'
                })
                return false
            }
            if (!this.tableData.every(item => {return item.production_model && item.number })) {
                this.$message({
                    message: '请完善商品信息',
                    type: 'error'
                })
                return false
            }
            const production = this.tableData.map(item => {
                return {
                    production_model: item.production_model,
                    remark: item.remark,
                    quantity: item.number
                }
            })
            const res = await this.$http.post('/api/quotation/confirm', { production: production })
            console.log(res)
        },
        successHandle (res) {
            console.log(res)
            this.tableData = res
        },
        downloadHandle () {
            window.open(process.env.VUE_APP_BASEURL + '/pongleng_batch_template.xlsx')
        },
        indexMethod (index) {
            return index + 1
        },
        addHandle () {
            const obj = {
                num: this.tableData.length + 1,
                production_model: '',
                remark: '',
                number: ''
            }
            this.tableData.push(obj)
        },
        deleteHandle () {
            if (this.multipleSelection.length === 0) {
                this.$message({
                    message: '请选择要删除的商品',
                    type: 'error'
                })
                return false
            }
            const arr = []
            this.tableData.forEach(item => {
                let flag = false
                this.multipleSelection.forEach(val => {
                    if (val === item) {
                        flag = true
                    }
                })
                if (!flag) {
                    arr.push(item)
                }
            })
            // const arr = this.tableData.filter(item => {
            //     return !this.multipleSelection.some(val => { val === item})
            // })
            console.log(arr)
            this.tableData = arr
            this.multipleSelection = []
            // this.$refs.multipleTable.clearSelection()
        },
        handleSelectionChange (data) {
            this.multipleSelection = data
        }
    }
}
</script>
<style lang="scss" scoped>
.quotation-index {
    padding-bottom: 40px;
}
.page-title {
    line-height: 1;
    border-left: 3px solid #3385FF;
    font-size: 16px;
    padding-left: 10px;
}
.list-box {
    margin-top: 40px;
    .next {
        margin-top: 20px;
        width: 180px;
    }
    .actions {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right {
            display: flex;
            align-items: center;
        }
    }
    .table-container {
        margin-top: 20px;
        .cell {
            position: relative;
            input {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border: 0 none;
                outline: none;
                padding: 0 20px;
                text-align: center;
            }
        }
    }
}
.info-box {
    padding-top: 10px;
    .info-content {
        margin-top: 20px;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
    }
    .content-item {
        display: flex;
        // font-size: 16px;
        line-height: 40px;
        border-radius: 5px;
        &:first-child {
            .label {
                border-bottom: 1px solid #ffffff;
            }
            .value {
                border-bottom: 1px solid #f5f5f5;
            }
        }
        .label {
            width: 150px;
            background-color: #eeeff3;
            padding: 0 38px;
        }
        .value {
            flex: 1;
            padding: 0 30px;
        }
    }
}
</style>
